import React from 'react';
import { gql } from '@apollo/client';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading } from 'uitk-react-text';
import { BannerFieldsFragment } from 'common/__generated__/api/types';
import { FragmentComponent } from 'components/component-types';
const fragment = gql`
  fragment BannerFields on HelpCenterWelcomeBanner {
    heading
    heroImage {
      url
      description
    }
    subHeading
  }
`;

interface BannerProps {
  data: BannerFieldsFragment;
}

export const WelcomeBanner: FragmentComponent<BannerProps, BannerFieldsFragment> = (props: BannerProps) => {
  const data = props.data;
  return (
    <div className={'welcome-div'}>
      <UitkLayoutFlex justifyContent="center" wrap="nowrap" className={'welcome-banner no-border'} data-stid="banner">
        <UitkLayoutFlexItem>
          <UitkLayoutFlex justifyContent="center" alignItems="start" direction={'column'}>
            <UitkHeading tag="h1" align="center" className={'welcome-heading'} data-stid="bannerHeading">
              {data.heading}
            </UitkHeading>
            <UitkHeading tag="h2" align="left" className={'welcome-subheading'} data-stid="bannerSubHeading">
              {data.subHeading}
            </UitkHeading>
          </UitkLayoutFlex>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </div>
  );
};
WelcomeBanner.fragment = fragment;
WelcomeBanner.displayName = 'Banner';
