import qs from 'qs';

export const processFeatureGateOverride = () => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (!params.featureGates) {
    return;
  }
  const date = new Date();
  const TWO_WEEKS_IN_MILLI = 14 * 24 * 60 * 60 * 1000;
  date.setTime(date.getTime() + TWO_WEEKS_IN_MILLI);
  if (window.document.cookie.includes('featureGates')) {
    window.document.cookie =
      'featureGates' + '=' + (params.featureGates || '') + '; expires=' + date.toUTCString() + '; path=/';
  } else {
    window.document.cookie =
      'featureGates' + '=' + (params.featureGates || '') + '; expires=' + date.toUTCString() + '; path=/';
    window.location.reload();
  }
};
