import * as React from 'react';
import { CustomerNotification, CustomerNotificationLocation } from '@shared-ui/customer-notifications';
import { inject } from 'mobx-react';
import { PageStore } from 'bernie-plugin-mobx';
import { ClientLogger } from 'bernie-client';
import { EVENTS } from 'utils/logger-events';

interface CustomerNotificationsProps {
  location: CustomerNotificationLocation;
  page?: PageStore;
}

const logger = ClientLogger.getLoggerWithIdentifier('customer-notification');

export const CustomerNotifications: React.FC<CustomerNotificationsProps> = inject('page')(
  // eslint-disable-next-line react/display-name
  React.memo((props: CustomerNotificationsProps) => {
    const { location: notificationLocation, page } = props;

    if (!page || !page.pageId) {
      logger.logEvent(EVENTS.PAGE_ID_NOT_FOUND, { message: 'PageId not found in Customer NotificationComponent' });
      return null;
    }

    const customerNotificationsClasses = `customer-notifications`;

    const customerNotificationInputs = {
      notificationLocation,
      xPageId: page.pageId,
    };
    return (
      <div role="region" className={customerNotificationsClasses}>
        <CustomerNotification inputs={customerNotificationInputs} />
      </div>
    );
  })
);
