import React, { useCallback, useMemo, useEffect } from 'react';
import { HelpArticles, ArticleIdentifier } from '@shared-ui/retail-help-center-help-articles';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import qs from 'qs';
import { isFromOldCspLink, getCurrentCspArticleId } from './cspHelper';

  export const HelpArticlesWrapper: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const urlQueryParam = useMemo(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { articleId, product, productId, searchTerm, isCategory } = params;
    const result = { articleId, product, productId, searchTerm, isCategory };
    if (Object.values(result).every((value) => value === undefined)) {
      return undefined;
    }
    return result;
  }, []);

  useEffect(() => {
    if (isFromOldCspLink()) {
      const id = getCurrentCspArticleId();
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      params['articleId'] = id;
      navigate(
        {
          pathname: location.pathname,
          search: qs.stringify(params),
        },
        { replace: true }
      );
      window.location.reload();
    }
  }, []);

  const updateParam = (newArticleIdentifier: ArticleIdentifier, currentParams, key: keyof ArticleIdentifier) => {
    if (newArticleIdentifier[key]) {
      currentParams[key] = newArticleIdentifier[key];
    } else {
      delete currentParams[key];
    }
  };

  const onDialogChangeCallback = useCallback(
    (newArticleIdentifier: ArticleIdentifier) => {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      updateParam(newArticleIdentifier, params, 'articleId');
      updateParam(newArticleIdentifier, params, 'product');
      updateParam(newArticleIdentifier, params, 'productId');
      updateParam(newArticleIdentifier, params, 'isCategory');
      updateParam(newArticleIdentifier, params, 'searchTerm');
      navigate(
        {
          pathname: location.pathname,
          search: qs.stringify(params),
        },
        { replace: true }
      );
    },
    [navigate]
  );

  return (
    <HelpArticles inputs={undefined} urlQueryParam={urlQueryParam} onDialogChangeCallback={onDialogChangeCallback} />
  );
};
