import React from 'react';
import { gql } from '@apollo/client';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { FitContent, UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkCard } from 'uitk-react-cards';
import { UitkHeading } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkSheetFooter } from 'uitk-react-sheet';
import { buildClickStreamAnalyticsForSignInCtaActionEvent } from 'common/helper';
import { FragmentComponent } from 'components/component-types';
import { EGDSIllustration } from '../EgdsComponents/egds-illustration';
import { SignInCTAFieldsFragment } from 'common/__generated__/api/types';
import { EGDSButton } from '../EgdsComponents/egds-button';
import { useCookies } from 'react-cookie';
import { ClickstreamAnalyticsEvent, ClickstreamAnalyticsExperience } from '../__fragments/ClickstreamEvent';
import { egClickStreamConstants } from 'common/constant';

const fragment = gql`
  fragment SignInCTAFields on SignInCTA {
    heading
    illustration {
      ...EGDSIllustration
    }
    signInButton {
      ...EGDSButton
    }
    dismissButton {
      ...EGDSButton
    }
    signInCTAClickstreamPayload {
      event {
        ...ClickstreamAnalyticsEvent
      }
      experience {
        ...ClickstreamAnalyticsExperience
      }
    }
    signInCTACookiePayload {
      cookieName
      cookieTTL
    }
  }

  ${EGDSIllustration.fragment}
  ${EGDSButton.fragment}
  ${ClickstreamAnalyticsEvent}
  ${ClickstreamAnalyticsExperience}
`;

export const SignInCta: FragmentComponent<object, SignInCTAFieldsFragment> = ({ data }) => {
  const [cookies, setCookie] = useCookies();
  const trackClick = useEgClickstream();

  const dismissSignInCta = () => {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getTime() + data.signInCTACookiePayload.cookieTTL);
    setCookie(data.signInCTACookiePayload.cookieName, 'yes', { path: '/helpcenter', expires: expirationDate });
    trackClick(
      buildClickStreamAnalyticsForSignInCtaActionEvent(
        egClickStreamConstants.egClickStreamEventNames.helpCenterSignInClosed,
        data.signInCTAClickstreamPayload.event,
        data.signInCTAClickstreamPayload.experience
      )
    );
  };

  const trackSignInAction = () => {
    trackClick(
      buildClickStreamAnalyticsForSignInCtaActionEvent(
        egClickStreamConstants.egClickStreamEventNames.helpCenterSignInSelected,
        data.signInCTAClickstreamPayload.event,
        data.signInCTAClickstreamPayload.experience
      )
    );
  };

  const Banner = (
    <div data-stid="signInCTA-container">
      <UitkSpacing padding={{ blockstart: 'six' }}>
        <div>
          <UitkLayoutGrid space="three" columns={[FitContent('280px')]} justifyContent="center">
            <UitkLayoutGridItem>
              <UitkSpacing padding={{ inline: 'six', block: 'six' }}>
                <UitkCard>
                  <UitkSheetFooter>
                    <UitkSpacing>
                      <UitkLayoutFlex alignItems="center" justifyContent="center" space="four" direction="column">
                        <UitkLayoutFlexItem alignSelf="center">
                          <EGDSIllustration data={data.illustration} />
                        </UitkLayoutFlexItem>
                        <UitkLayoutFlexItem alignSelf="center">
                          <UitkHeading align="center" size={6} tag="h2" data-stid="signInCTA-heading">
                            {data.heading}
                          </UitkHeading>
                        </UitkLayoutFlexItem>
                        <UitkLayoutFlexItem alignSelf="center">
                          <UitkSpacing margin={{ blockstart: 'three' }}>
                            <EGDSButton
                              data={data.signInButton}
                              onClick={trackSignInAction}
                              data-stid="signInCTA-SignInButton"
                            ></EGDSButton>
                          </UitkSpacing>
                        </UitkLayoutFlexItem>
                        <UitkLayoutFlexItem alignSelf="center">
                          <UitkSpacing margin={{ blockend: 'six' }}>
                            <EGDSButton
                              data={data.dismissButton}
                              onClick={dismissSignInCta}
                              data-stid="signInCTA-NotRightNowButton"
                            ></EGDSButton>
                          </UitkSpacing>
                        </UitkLayoutFlexItem>
                      </UitkLayoutFlex>
                    </UitkSpacing>
                  </UitkSheetFooter>
                </UitkCard>
              </UitkSpacing>
            </UitkLayoutGridItem>
          </UitkLayoutGrid>
        </div>
      </UitkSpacing>
    </div>
  );
  return cookies[data.signInCTACookiePayload.cookieName] !== 'yes' ? Banner : null;
};

SignInCta.fragment = fragment;
SignInCta.displayName = 'SignInCTAView';
