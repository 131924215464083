import React from 'react';
import { CustomerNotificationLocation } from '@shared-ui/customer-notifications';
import { CustomerNotifications } from 'components/CustomerNotification';

export const AppDownloadBanner = () => {
  return (
    <div data-stid="notifications-footer">
      <CustomerNotifications location={CustomerNotificationLocation.FOOTER} />
    </div>
  );
};
