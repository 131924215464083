import { gql } from 'graphql-request';

export const ClickstreamAnalyticsEvent = gql`
  fragment ClickstreamAnalyticsEvent on EGClickstreamEvent {
    eventName
    eventType
    eventCategory
    eventVersion
    actionLocation
  }
`;

export const ClickstreamAnalyticsExperience = gql`
  fragment ClickstreamAnalyticsExperience on Experience {
    appName
    appVersion
    pageName
    pageNameDetailed
  }
`;
