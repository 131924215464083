import * as React from 'react';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkCard } from 'uitk-react-cards';
import { UitkButtonSize, UitkSecondaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { TripInfoDetailFields } from 'common/__generated__/api/types';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { buildClickStreamForBookingActions } from 'common/helper';
import { ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import TripInfos = TripInfoDetailFields.tripInfos;
import { ClientLogger } from 'bernie-client';
import { EVENTS } from 'utils/logger-events';
import {VirtualAgentControlClientConfig} from '@shared-ui/customer-virtual-agent-control';
import { useVirtualAgentContext } from '@shared-ui/retail-help-center-util';

type StandAloneVAUrlParam = Pick<
  VirtualAgentControlClientConfig,
  'intentMessage' | 'conversationId' | 'participantId' | 'mode'
>;

interface TripInfoDetailsProps {
  setTripInfoData?: (value) => void;
  tripInfoData: TripInfos;
  mobileView?: boolean;
}

const logger = ClientLogger.getLoggerWithIdentifier('TripInfoDetail');

const TripInfoDetail: React.FC<TripInfoDetailsProps> = ({
  setTripInfoData,
  tripInfoData,
  mobileView = false,
}: TripInfoDetailsProps) => {
  const trackClick = useEgClickstream();
  const { controller } = useVirtualAgentContext();

  return (
    <>
      <Viewport>
        <ViewSmall>
          <UitkSpacing margin={{ blockend: 'six' }}>
            <UitkSecondaryButton size={UitkButtonSize.LARGE} onClick={() => setTripInfoData(null)}>
              <UitkIcon name={'arrow_back_iOS'} />
              {tripInfoData.backToTripButton}
            </UitkSecondaryButton>
          </UitkSpacing>
        </ViewSmall>
        {!mobileView ? <ViewMedium /> : <></>}
      </Viewport>
      <UitkCard>
        {tripInfoData.tripBookings.map((booking, index) => {
          if (booking.__typename == 'BookingDetail') {
            return (
              <UitkSpacing margin={{ blockend: 'two' }} key={`trip-detail-${index}`}>
                <UitkCard padded border>
                  <UitkLayoutFlex space="two" alignItems="center">
                    <UitkLayoutFlexItem>
                      <UitkIcon name={booking.icon.id} />
                    </UitkLayoutFlexItem>
                    <UitkLayoutFlexItem>
                      <div>
                        <UitkHeading tag="h4" size={6}>
                          {booking.title}
                        </UitkHeading>
                        <UitkText size={200}>{booking.timePeriod}</UitkText>
                        <UitkText size={200}>{booking.itineraryNumberText}</UitkText>
                      </div>
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>

                  <UitkSpacing margin={{ blockstart: 'two' }}>
                    <UitkLayoutFlex space="three" alignItems="end" wrap={'wrap'}>
                      {booking.bookingActions.map((action, index) => (
                        <UitkLayoutFlexItem key={`trip-action-${index}`}>
                          {action.__typename == 'HelpCenterIntentButtons' ? (
                            <UitkSecondaryButton
                              type="button"
                              size={UitkButtonSize.SMALL}
                              onClick={async () => {
                                const message: StandAloneVAUrlParam['intentMessage'] = {
                                  text: action.vaText,
                                  messageType: 'POSTBACK',
                                  intent: action.skillName,
                                };
                                if (action.payload) {
                                  message.payload = action.payload;
                                }
                                logger.logEvent(EVENTS.PERSONALIZATION_INTENT_CLICK, {
                                  message: 'PERSONALIZATION_INTENT_CLICK',
                                  intent: message.intent,
                                  product: booking.product,
                                });
                                controller.sendMessage(message)
                                trackClick(
                                  buildClickStreamForBookingActions(action.bookingActionsClickStreamAnalytics)
                                );
                              }}
                            >
                              {action.name.primary}
                            </UitkSecondaryButton>
                          ) : (
                            <UitkSecondaryButton
                              type="button"
                              tag="a"
                              href={
                                action.name.action.__typename == 'UILinkAction' ? action.name.action.resource.value : ''
                              }
                              target="_blank"
                              size={UitkButtonSize.SMALL}
                            >
                              {action.name.primary}
                            </UitkSecondaryButton>
                          )}
                        </UitkLayoutFlexItem>
                      ))}
                    </UitkLayoutFlex>
                  </UitkSpacing>
                </UitkCard>
              </UitkSpacing>
            );
          } else if (booking.__typename == 'UnSupportedBooking') {
            return (
              <UitkCard padded border>
                <UitkSpacing margin={{ blockstart: 'two' }}>
                  <UitkHeading tag="h4" size={6}>
                    {booking.heading}
                  </UitkHeading>
                </UitkSpacing>
                <UitkText size={300}>{booking.description}</UitkText>
                <UitkSpacing margin={{ blockstart: 'three' }}>
                  <UitkSecondaryButton
                    type="button"
                    tag="a"
                    href={
                      booking.goToTrips.action.__typename == 'UILinkAction'
                        ? booking.goToTrips.action.resource.value
                        : ''
                    }
                    target="_blank"
                    size={UitkButtonSize.SMALL}
                    onClick={async () => {
                      trackClick(buildClickStreamForBookingActions(booking.clickstreamPayload));
                    }}
                  >
                    <UitkIcon name={booking.goToTrips.icon.id} />
                    {booking.goToTrips.primary}
                  </UitkSecondaryButton>
                </UitkSpacing>
              </UitkCard>
            );
          }
        })}
      </UitkCard>
    </>
  );
};

export default TripInfoDetail;
