import React from 'react';
import { gql } from '@apollo/client';
import { UitkIllustrations } from 'uitk-react-illustrations';
import { FragmentComponent } from 'src/components/component-types';
import { EGDSIllustrationFragment } from 'src/common/__generated__/api/types';

const __egdsIllustrationFragment = gql`
  fragment EGDSIllustration on Illustration {
    url
    description
    id
  }
`;

interface EGDSIllustrationProps {
  data: EGDSIllustrationFragment;
}

export const EGDSIllustration: FragmentComponent<object, EGDSIllustrationFragment> = ({
  data,
}: EGDSIllustrationProps) => {
  return <UitkIllustrations alt={data.description} url={data.url} />;
};
EGDSIllustration.fragment = __egdsIllustrationFragment;
EGDSIllustration.displayName = 'EGDSIllustration';
