import React from 'react';
import { gql } from '@apollo/client';
import { UitkPrimaryButton, UitkTertiaryButton, UitkButtonProps } from 'uitk-react-button';
import { FragmentComponent } from '../../component-types';
import { EGDSButtonFragment } from 'src/common/__generated__/api/types';

const __egdsButtonFragment = gql`
  fragment EGDSButton on EGDSButton {
    __typename
    primary
    action {
      accessibility
      analytics {
        linkName
        referrerId
      }
      ... on UILinkAction {
        resource {
          __typename
          value
        }
      }
    }
  }
`;

interface EGDSButtonProps {
  data: EGDSButtonFragment;
  onClick: () => void;
}

export const EGDSButton: FragmentComponent<EGDSButtonProps, EGDSButtonFragment> = ({
  data,
  onClick,
  ...otherProps
}: EGDSButtonProps) => {
  const { primary, __typename } = data;

  const buttonProps: UitkButtonProps = {
    type: 'button',
    onClick: onClick,
  };

  if (__typename === 'UIPrimaryButton' && data.action.__typename === 'UILinkAction') {
    buttonProps['tag'] = 'a';
    buttonProps['isFullWidth'] = true;
    buttonProps['href'] = data.action.resource.value;
  }

  switch (__typename) {
    case 'UIPrimaryButton':
      return (
        <UitkPrimaryButton {...buttonProps} {...otherProps}>
          {primary}
        </UitkPrimaryButton>
      );
    case 'UITertiaryButton':
      return (
        <UitkTertiaryButton {...buttonProps} {...otherProps}>
          {primary}
        </UitkTertiaryButton>
      );
  }
};
EGDSButton.fragment = __egdsButtonFragment;
EGDSButton.displayName = 'EGDSButton';
