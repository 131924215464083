import React from 'react';
import { gql } from '@apollo/client';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';
import { UitkImage, UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { UitkCard } from 'uitk-react-cards';
import { UitkSpacing } from 'uitk-react-spacing';
import { FragmentComponent } from 'components/component-types';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { TaapAcademyComponentFieldsFragment } from 'common/__generated__/api/types';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import {
  buildClickStreamAnalyticsForTaapAcademySelectedEvent,
} from 'common/helper';

const fragment = gql`
  fragment TaapAcademyComponentFields on TaapAcademyComponent {
    heading
    description
    iconUrl
    academyLink {
      text
      action {
        accessibility
        resource {
          value
        }
      }
    }
  }
`;

interface TaapAcademyComponentProps {
  data: TaapAcademyComponentFieldsFragment;
}

export const TaapAcademy: FragmentComponent<TaapAcademyComponentProps, TaapAcademyComponentFieldsFragment> = (
  props: TaapAcademyComponentProps
) => {
  const { data } = props;
  const trackClick = useEgClickstream();
  return (
    <>
      <UitkCard padded border>
        <UitkLayoutGrid columns={['12x', '1fr']} space="four" alignItems="center">
          <UitkLayoutGridItem>
            <div>
              <UitkFigure ratio={UitkFigureAspectRatioType.R1_1}>
                <UitkImage src={data.iconUrl} alt="TAAP Academy Logo" />
              </UitkFigure>
            </div>
          </UitkLayoutGridItem>
          <UitkLayoutGridItem>
            <div>
              <UitkHeading size={7} tag="h2">
                {data.heading}
              </UitkHeading>
              <UitkText>{data.description}</UitkText>
              <UitkSpacing margin={{ blockstart: 'one' }}>
                <UitkLink
                  onClick={() => {
                    trackClick(buildClickStreamAnalyticsForTaapAcademySelectedEvent());
                  }}
                >
                  <a href={data.academyLink.action.resource.value}>{data.academyLink.text}</a>
                </UitkLink>
              </UitkSpacing>
            </div>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </UitkCard>
    </>
  );
};
TaapAcademy.fragment = fragment;
TaapAcademy.displayName = 'TaapAcademyComponent';
